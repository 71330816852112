import styled from "styled-components";
import React, { useEffect, useRef } from "react";
import { ITheme, useTheme } from '../providers/ThemeProvider';

const Canvas = styled.canvas`
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 0;
`;

class Particle {
  x: number = 0;
  y: number = 0;
  dx: number = 0;
  dy: number = 0;
  horizontal: boolean;
  opacity: number = 1;
  theme: ITheme;
  direction: number = 1

  height: number = 0;
  width: number = 0;
  ctx: CanvasRenderingContext2D;
  constructor(ctx: CanvasRenderingContext2D, theme: ITheme) {
    this.ctx = ctx;
    this.horizontal = false; //Math.random() > 0.5;
    this.theme = theme;
    this.direction = Math.random() > 0.5 ? 1 : -1
    this.reset();

    if (this.horizontal) {
      this.height = 1;
      this.x = Math.random() * window.innerWidth;
    } else {
      this.width = 1;
      this.y = Math.random() * window.innerHeight;
    }
  }

  reset() {
    this.opacity = (20 + Math.random() * 40) / 100;
    const speed = this.direction * (1 + Math.random() * 1) / 5;
    if (this.horizontal) {
      this.width = Math.random() * 20;
      this.x = this.direction === 1 ? -this.width : window.innerWidth + this.width;
      const Y = Math.random() * window.innerHeight;
      this.y = Y - (Y % 20);
      this.dx = speed;
    } else {
      this.height = Math.random() * 20;
      this.y = this.direction === 1 ? -this.height : window.innerHeight + this.height;
      const X = 20 + Math.random() * (window.innerWidth - 40);
      this.x = X - (X % 20);
      this.dy = speed;
    }
  }

  render(cursorPosition: { x: number; y: number }) {
    this.ctx.beginPath();
    this.ctx.rect(this.x, this.y, this.width, this.height);
    this.ctx.globalAlpha = this.opacity
    this.ctx.fillStyle = `${this.theme.text}22`;
    this.ctx.fill();
    this.ctx.globalAlpha = 1

    // √[( y2 –  y1)² + ( x1 –  x2)²]
    const distanceX = Math.sqrt(Math.pow(this.x - cursorPosition.x, 2));
    const distanceY = Math.sqrt(Math.pow(this.y - cursorPosition.y, 2));

    // const distance = Math.sqrt(
    //   Math.pow(this.y - cursorPosition.y, 2) +
    //     Math.pow(this.x - cursorPosition.x, 2)
    // );
    this.x +=
      distanceY < 30 && this.horizontal ? Math.min(this.dx * 3, 5) : this.dx;
    this.y +=
      distanceX < 30 && !this.horizontal ? Math.min(this.dy * 3, 5) : this.dy;

    if (
      this.y + this.height > window.innerHeight ||
      this.x + this.width > window.innerWidth
    ) {
      this.reset();
    }
  }
}

const PARTICLES_DENSITY = 0.05;

const BlueprintCanvas = () => {
  const canvasRef = useRef<HTMLCanvasElement | null>(null);
  const { theme } = useTheme()
  const cursorPosition = useRef({ x: window.innerWidth / 2, y: window.innerHeight / 2 });
  const particlesRef = useRef<Particle[]>([]);
  const animationFrameRef = useRef<number | null>(null);
  const delayedY = useRef<number>(0);
  const delayedX = useRef<number>(0);

  const renderHorizontalLine = (x: number, bold: boolean) => {
    const ctx = canvasRef?.current?.getContext("2d")!;
    const height = window.innerHeight;
    ctx.beginPath();
    ctx.moveTo(x, 0);
    ctx.lineTo(x, height);
    if (x < delayedX.current + 20 && x > delayedX.current - 20) {
      ctx.strokeStyle = `${theme.accent}aa`;
    } else if (x < delayedX.current + 40 && x > delayedX.current - 40) {
      ctx.strokeStyle = `${theme.accent}66`;
    } else if (bold) {
      ctx.strokeStyle = `${theme.text}22`;
    } else {
      ctx.strokeStyle = `${theme.text}0f`;
    }
    ctx.stroke();
  };

  const renderVerticalLine = (y: number, bold: boolean) => {
    const ctx = canvasRef?.current?.getContext("2d")!;
    const width = window.innerWidth;
    ctx.beginPath();
    ctx.moveTo(0, y);
    ctx.lineTo(width, y);
    if (y < delayedY.current + 20 && y > delayedY.current - 20) {
      ctx.strokeStyle = `${theme.accent}aa`;
    } else if (y < delayedY.current + 40 && y > delayedY.current - 40) {
      ctx.strokeStyle = `${theme.accent}66`;
    } else if (bold) {
      ctx.strokeStyle = `${theme.text}22`;
    } else {
      ctx.strokeStyle = `${theme.text}0f`;
    }
    ctx.stroke();
  };

  const renderGrid = () => {

    let currX = 20;
    let currY = 20;
    const W = window.innerWidth;
    const H = window.innerHeight;

    while (currX < W) {
      renderHorizontalLine(currX, currX % 100 === 0);
      currX += 20;
    }
    canvasRef?.current?.getContext("2d")!.beginPath();
    while (currY < H) {
      renderVerticalLine(currY, currY % 100 === 0);
      currY += 20;
    }
  };

  const renderTarget = () => {
    const ctx = canvasRef.current!.getContext("2d")!;
    const X = delayedX.current - (delayedX.current % 20);
    const Y = delayedY.current - (delayedY.current % 20);
    ctx.beginPath();
    ctx.rect(X, Y, 20, 20);
    ctx.fillStyle = `${theme.accent}66`;
    ctx.fill();

    ctx.beginPath();
    ctx.rect(X + 20, Y - 20, 20, 20);
    ctx.rect(X + 20, Y, 20, 20);
    ctx.rect(X + 20, Y + 20, 20, 20);
    ctx.rect(X - 20, Y - 20, 20, 20);
    ctx.rect(X - 20, Y, 20, 20);
    ctx.rect(X - 20, Y + 20, 20, 20);
    ctx.rect(X, Y - 20, 20, 20);
    ctx.rect(X, Y + 20, 20, 20);
    ctx.fillStyle = `${theme.accent}11`;
    ctx.fill();
  };

  const getDistance = (p1: Particle, p2: Particle) => {
    return Math.sqrt(Math.pow(p2.y - p1.y, 2) + Math.pow(p1.x - p2.x, 2))
  }

  const drawLines = () => {
    const ctx = canvasRef?.current?.getContext("2d")!;
    particlesRef.current.map((particle, i) => {
      const neighbors = particlesRef.current.filter(p => getDistance(particle, p) < 80)
      neighbors.map(n => {
        ctx.beginPath()
        ctx.moveTo(particle.x, particle.y)
        ctx.lineTo(n.x, n.y)
        ctx.moveTo(n.x, n.y + n.height)
        ctx.lineTo(particle.x, particle.y + particle.height)
        if (getDistance(particle, n) <= 60 && particle.x !== n.x) {
          ctx.strokeStyle = `${theme.accent}22`
        } else {
          ctx.strokeStyle = `${theme.text}11`
        }
        ctx.stroke()
      })
    })
  }

  const animateCanvas = () => {
    const ctx = canvasRef?.current?.getContext("2d")!;
    if (!ctx) return null
    canvasRef.current
      ?.getContext("2d")
      ?.clearRect(0, 0, window.innerWidth, window.innerHeight);
    renderGrid();
    renderTarget();
    particlesRef.current.map((p) => p.render(cursorPosition.current));
    drawLines()
    animationFrameRef.current = requestAnimationFrame(animateCanvas);
  };

  const initCanvas = (canvas: HTMLCanvasElement | null) => {
    if (animationFrameRef.current) {
      cancelAnimationFrame(animationFrameRef.current)
    }
    if (!canvas?.getContext("2d")) return null;
    particlesRef.current = []
    for (let i = 0; i < PARTICLES_DENSITY * (window.innerWidth / 20 * window.innerHeight / 20); i++) {
      particlesRef.current.push(new Particle(canvas.getContext("2d")!, theme));
    }
    animationFrameRef.current = requestAnimationFrame(animateCanvas);
  };

  const resetCanvas = () => {
    if (!canvasRef.current) return
    canvasRef.current!.width = window.innerWidth;
    canvasRef.current!.height = window.innerHeight;

    canvasRef
      .current!.getContext("2d")!
      .clearRect(0, 0, window.innerWidth, window.innerHeight);
    
    initCanvas(canvasRef.current!);
  };

  const updateMousePos = (e: MouseEvent) => {
    cursorPosition.current = { x: e.clientX, y: e.clientY };
    delayedX.current = e.clientX;
    delayedY.current = e.clientY;
  };

  useEffect(() => {
    // if (!canvasRef.current) return;
    document.addEventListener("mousemove", updateMousePos);
    window.addEventListener("resize", resetCanvas);
    initCanvas(canvasRef.current);

    return () => {
      document.removeEventListener("mousemove", updateMousePos);
      window.removeEventListener("resize", resetCanvas);
      resetCanvas();
    };
  }, [theme]);

  useEffect(() => {
    // resetCanvas();
  });

  return (
    <Canvas
      ref={canvasRef}
      width={window.innerWidth}
      height={window.innerHeight}
    />
  );
};

export default BlueprintCanvas;
