import React from 'react';
import styled from 'styled-components';
import { FiCodepen, FiGithub, FiLinkedin } from 'react-icons/fi'
import { useTheme } from '../providers/ThemeProvider';

const Wrapper = styled.div`
  position: relative;
  padding-bottom: 80px;
  ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
  }
  li {
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    :hover {
      transition: stroke 200ms;
      svg {
        stroke: ${props => props.theme.text};
        transition: stroke 200ms;
      }
    }
  }
  :after {
    position: absolute;
    left: calc(50% - 3px);
    bottom: 0;
    content: '';
    height: 70px;
    width: 2px;
    border-right: 2px dashed ${props => props.theme.text};
  }
`

const SocialLinks = () => {
  const { theme } = useTheme()

  return <Wrapper>
    <ul>
      <li>
        <a href="https://github.com/BenAfonso" target="_blank" rel="noreferrer noopener">
          <FiGithub color={theme.accent} size='30px' strokeWidth={1} />
        </a>
      </li>
      <li>
        <a href="https://codepen.io/BenAfonso/" target="_blank" rel="noreferrer noopener">
          <FiCodepen color={theme.accent} size='30px' strokeWidth={1} />
        </a>
      </li>
      <li>
        <a href="https://www.linkedin.com/in/benjamin-afonso-9ab880113/" target="_blank" rel="noreferrer noopener">
          <FiLinkedin color={theme.accent} size='30px' strokeWidth={1} />
        </a>
      </li>
    </ul>
  </Wrapper>
}

export default SocialLinks
