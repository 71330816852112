import { graphql } from 'gatsby'
import * as React from "react"
import styled from 'styled-components'
import BlueprintCanvas from '../components/BlueprintCanvas'
import Layout from '../layouts/base'

const PageWrapper = styled.div`
  height: 100%;
  flex: 1;
  displaY: flex;
  flex-direction: column;
`

const Content = styled.div`
  z-index: 1;
  height: 100%;
  flex: 1;
  displaY: flex;
  flex-direction: column;
`

const BlueprintLayout = (props: { children: any }) => {
  if (typeof window === 'undefined') return null

  return (
    <Layout>
      <PageWrapper>
        <BlueprintCanvas />
        <Content>
          {props.children}
        </Content>
      </PageWrapper>
    </Layout>
  )
}

export default BlueprintLayout
