import { graphql } from 'gatsby'
import * as React from "react"
import { Trans } from 'react-i18next'
import styled from 'styled-components'
import SocialLinks from '../components/SocialLinks'
import BlueprintLayout from '../layouts/blueprint'

const PageWrapper = styled.div`
  height: 100%;
  flex: 1;
  displaY: flex;
  flex-direction: column;
`

const Wrapper = styled.main`
  flex: 1;
  height: 100%;
  display: flex;
  flex-direction: column;
  user-select: none;
  font-size: 40px;
  @media (max-width: 1020px) {
    font-size: 20px;
  }
  @media (max-width: 545px) {
    font-size: 15px;
  }

  @media (max-width: 421px) {
    font-size: 12px;
  }

`

const Hero = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex: 1;
  .emphasis {
    color: ${props => props.theme.accent};
    transform-origin: 50% 100%;
    font-size: 1em;
    font-weight: 400;
    line-height: 1.2;
  }
  h1, h2, .emphasis {
    position: relative;
    overflow: hidden;
    > span:first-child {
      visibility: hidden;
    }
    > span:nth-child(2) {
      position: absolute;
      left: 0;
      top: 0;
      transform: translateY(100%);
    }
  }
  .emphasis > span:nth-child(2) {
    animation: animate-text-in 400ms forwards ease-out 100ms;
  }
  h1 > span:nth-child(2) {
    animation: animate-text-in 400ms forwards ease-out 200ms;
  }
  h2 > span:nth-child(2) {
    animation: animate-text-in 400ms forwards ease-out 400ms;
  }
  h1, h2 {
    font-weight: 600;
    text-transform: uppercase;
    color: ${props => props.theme.text};
    margin: 0;
  }

  h1 {
    line-height: 1;
    font-size: 3.1em;
  }
  h2 {
    opacity: 0.34;
    line-height: 1;
    margin-top: 10px;
    font-size: 1.5em;
  }

  @keyframes animate-text-in {
    from {
      transform: translateY(100%);
    }
    to {
      transform: translateY(0);
    }
  }

  @media (max-width: 421px) {
    .emphasis {
      font-size: 1.8em;
    }
    h1 {
      font-size: 4em;
    }
  }

  
`

const SocialLinksWrapper = styled.div`
  position: fixed;
  bottom: 0px;
  left: 40px;
`

const Footer = styled.div`
  margin-left: 60px;
`

const CurrentPosition = styled.div`
  text-transform: uppercase;
  line-height: 1.2;
  h2, h3 {
    margin: 0;
    padding: 0;
    font-weight: 500;
    color: ${props => props.theme.text};

  }
  h2 {
    font-size: 0.5em;
  }
  h3 {
    font-size: 1em;
    opacity: 0.17
  }
`

// markup
const IndexPage = () => {
  if (typeof window === 'undefined') return null

  return (
    <BlueprintLayout>
      <Wrapper>
        <Hero>
          <div className='emphasis'>
            <span><Trans>Hello, my name is</Trans></span>
            <span><Trans>Hello, my name is</Trans></span>
          </div>
          <h1>
            <span><Trans>Ben Afonso</Trans></span>
            <span><Trans>Ben Afonso</Trans></span>
          </h1>
          <h2>
            <span><Trans>I build things on the internet</Trans></span>
            <span><Trans>I build things on the internet</Trans></span>
          </h2>
        </Hero>
        <Footer>
          <CurrentPosition>
            <h2><Trans>Current Position</Trans></h2>
            <h3><Trans>CTO @ Night Zookeeper</Trans></h3>
          </CurrentPosition>
        </Footer>
        <SocialLinksWrapper>
          <SocialLinks />
        </SocialLinksWrapper>
      </Wrapper>
    </BlueprintLayout>
  )
}

export const query = graphql`
  query($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;

export default IndexPage
